/* eslint-disable no-console */

import axios from 'axios'
import jwt from 'jsonwebtoken'

/*
const apiClient = axios.create({
  //  baseURL: `http://localhost:3000`,
  withCredentials: false, // This is the default
})
*/

export default {
  secret: 'e2ffccce2a69190b8f3f16896b2cadeae380caaaae7e7fac2f31a83bd55757c5',

  getKey(filename) {
    //console.log('getkey:'+filename);

    this.secret='e2ffccce2a69190b8f3f16896b2cadeae380caaaae7e7fac2f31a83bd55757c5';

    return axios.get(filename);
  },
  
  getcandidatura(con, data, cb) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda getcandidatura');
      var url = "./getcandidatura"; // state.connection.mytoken
      var payload = {
        token: con.token,
        loginData: data,
      }
      //console.log(payload);
      var options= { };
      if (cb) {
        options.onUploadProgress=cb;
      }
      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload }, options ).then(function(resp) {
        //console.log('ricevuto:'+data.callname);
        resolve(resp.data);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  getcandidatura2( data, cb) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda getcandidatura');
      var url = "/getcandidatura"; // state.connection.mytoken

      let secretHash='92a64beefa0a78c2ad68c45aa906f13d86462f9fa5efb91746d027f2b3892364';
      var token = jwt.sign( 'no secret big secret', secretHash ); // con.key, { algorithm: 'RS256' });

      var payload = {
        token: token,
        loginData: data,
      }
      //console.log(payload);
      var options= { };
      if (cb) {
        options.onUploadProgress=cb;
      }
      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload }, options ).then(function(resp) {
        //console.log('ricevuto:'+data.callname);
        resolve(resp.data);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  addIP(data){
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda IP address');
      var url = "./addip"; // state.connection.mytoken

      let secretHash='92a64beefa0a78c2ad68c45aa906f13d86462f9fa5efb91746d027f2b3892364';
      var token = jwt.sign( 'no secret big secret', secretHash ); // con.key, { algorithm: 'RS256' });

      var payload = {
        token: token,
        loginData: data,
      }
      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload }).then(function(resp) {
        //console.log('ricevuto:'+data.callname);
        resolve(resp.data);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  getStato(data){
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda getStato');
      var url = "/getstato"; // state.connection.mytoken

      let secretHash='92a64beefa0a78c2ad68c45aa906f13d86462f9fa5efb91746d027f2b3892364';
      var token = jwt.sign( 'no secret big secret', secretHash ); // con.key, { algorithm: 'RS256' });

      var payload = {
        token: token,
        loginData: data,
      }
      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload }).then(function(resp) {
        //console.log('ricevuto:'+data.callname);
        resolve(resp.data);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  loginbyid(con, data) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./myid"; // state.connection.mytoken
      var payload = {
        // token: con.mytoken,
        loginData: data
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        resolve(data);
      }).catch(function(err) {
        console.log('problemi');
        reject(err);
      });
        
    });
  },

  query(con, soql) {
    var self=this;
    return new Promise(function(resolve,reject) {
      console.log('manda la query');
      var url = "./query"; // state.connection.mytoken
      var payload = {
        token: con.token,
        query: soql
      }
      // console.log(payload);

      var jwtpayload = jwt.sign( payload, self.secret ); // con.key, { algorithm: 'RS256' });

      axios.post(url, { payload: jwtpayload } ).then(function(resp) {
        console.log('ricevuto');
        var data = resp.data;
        if (data.records) {
          resolve(data.records);
        }
      }).catch(function(err) {
        console.log('problemi: '+err);
        reject(err);
      });
        
    });
  },

}
