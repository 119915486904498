export default {
    "Fatturazione": "Billing",
    "rossoCandidatura": "Red: Candidacy not accepted",
    "gialloCandidatura": "Yellow: Candidacy in validation",
    "verdeCandidatura": "Green: Valid candidacy",
    "grigioDocumenti": "Gray: Documents not yet requested",
    "verdeDocumenti": "Green: Required documents provided",
    "gialloDocumenti": "Yellow: Required documents not yet provided",
    "grigioEdizione": "Gray: Edition not yet definable",
    "gialloEdizione": "Yellow: Edition to be confirmed",
    "verdeEdizione": "Green: Confirmed edition",
    "gialloLista": "Yellow: Waiting list",
    "grigioIscrizione": "Gray: Signing Up pending confirmation",
    "gialloIscrizione": "Yellow: Signing Up to be made",
    "verdeIscrizione": "Green: Signing Up completed",
    "grigioPagamento": "Gray: Payment not yet requested",
    "verdePagEff": "Green: payment made",
    "gialloPagamento": "Yellow: Payment to be made",
    "grigio": "Gray: still in progress",
    "InCorso": "In Progress",
    "NonAccettata": "Not Accepted",
    "rosso": "Red: Not perfected",
    "verde": "Green: Completed",
    "Lezione": "Lesson",
    "Tipo": "Type",
    "Entro": "Within",
    "Caricato": "Uploaded",
    "Fattura": "Invoice",
    "Importo": "Amount",
    "Scadenza": "Deadline",
    "Pagatail": "Payment Date",
    "Candidatura": "Application",
    "Data": "Date",
    "Stato": "Status",
    "Oggetto": "Subject",
    "Foto": "Photo",
    "Corso": "Course",
    "cambialingua": "Versione Italiana",
    "registrati": "SIGN UP",
    "title": "Example is the highest form of teaching",
    "subtitleHome": "Welcome to Alma",
    "subtitleRicette": "Recipes and News",
    "subtitleFacilitazioni": "Payment facilities",
    "subtitleInfo": "General Information",
    "subtitleAlma": "My ALMA Life",
    "subtitleHousing": "My ALMA Housing",
    "subtitleAssistenza": "Support and Service",
    "subtitleStudente": "Students Area",
    "subtitleExtraDidattica": "Events and spare time?",
    "subtitleDidattica": "Education and teaching",
    "subtitleDiplomati": "Graduates",
    "subtitleCandidati": "Applicants",
    "subtitleCandidatura": "Applications",
    "subtitleStoricoCandidature": "Applications log",
    "subtitleProfiloPersonale": "Personal profile",
    "subtitleELearning": "E-learning | Teaching Materials",
    "subtitleInterventi": "Your tickets", //"maintenance requests",
    "subtitleCorsi": "Courses and Open Days",
    "subtitlePrimoGiorno": "First Day",
    "subtitleSponsor": "Partners",
    "subtitleChiediAssistenza": "Request Info",
    "messaggioChiediAssistenza": "If you have decided to apply or are applying and you have difficulties, please contact these references",
    "emailChiediAssistenza": "E-Mail",
    "telefonoChiediAssistenza": "Phone No.",
    "subtitleRegolamento": "School Regulation",
    "subtitleRegolamentoHousing": "ALMA Housing regulations",
    "USERNAME": "USERNAME",
    "NuovaRichiesta": "New Request",
    "IndirizzoMail": "E-Mail Address",
    "E-Mail": "E-Mail",
    "E-Mail(ripetere)": "E-Mail (repeat)",
    "cambiaMail": "Change E-Mail",
    "nuovaMail": "New Email",
    "confermaMail": "Confirm Email",
    "Salva": "Save",
    "Annulla": "Cancel",
    "Invia": "Send",
    "CambiaPassword": "Change Password",
    "Passwordattuale": "Current Password",
    "PrecedentePassword": "Previous Password",
    "NuovaPassword": "New Password",
    "ConfermaPassword": "Confirm Password",
    "SuccessRequest":"New Password set!",
    "InvalidToken":"Password recovery link no longer valid!",
    "PasswordsNoMatch":"Password no match!",
    "InvalidRequest":"Invalid request, contact support!",
    "Datidiaccesso": "Access Data",
    "DatiPersonali": "Personal Data",
    "Nome": "First Name",
    "Cognome": "Last Name",
    "Telefono": "Phone",
    "Anagrafica": "Registry",
    "MailSecondaria": "Secondary Email",
    "Emailalternativa": "Other email",
    "Cellulare": "Mobile Phone",
    "AltroCellulare": "Other Mobile Phone",
    "NumeroAlternativo": "Other phone number",
    "DatidiNascita": "Birth data",
    "CodiceFiscale": "Tax payer code",
    "Sesso": "Gender",
    "DatadiNascita": "Date Of Birth",
    "CittàdiNascita": "City Of Birth",
    "Città": "City",
    "Scegliunaopzione": "Choose an option",
    "Opzione1": "Option 1",
    "Opzione2": "Option 2",
    "ProvinciadiNascita": "Province Of Birth",
    "NazionediNascita": "Country Of Birth",
    "Nazione": "Country",
    "Cittadinanza": "Citizenship",
    "SecondaCittadinanza": "Second Citizenship",
    "Residenza": "Residence",
    "Indirizzo": "Address",
    "Indietro": "Back",
    "IndirizzoResidenza": "Residence Address",
    "CittàdiResidenza": "City of residence",
    "CAP": "POSTAL CODE",
    "ProvinciadiResidenza": "Province of Residence",
    "NazionediResidenza": "Country of Residence",
    "Percorsodistudi": "Educational path",
    "QualificaoTitolodiStudio": "Level of Degree",
    "Annoconseguimentoqualifica": "Year of Graduation",
    "Annoqualifica": "Year of Graduation",
    "NomeIstituto": "Name of Institute",
    "Istituto": "Institute",
    "CittàIstituto": "City of Institute",
    "NazioneIstituto": "Country of Institute",
    "FrequentatoAlma": "Have you ever attended any other cooking schools?",
    "Si": "Yes",
    "ConosciutoAlma": "How did you get to know ALMA?",
    "AltraScuolaCucina": "Which cooking school did you attend?",
    "Social": "I am  ALMA follower on Social media",
    "Passato": "I attended ALMA courses in the past",
    "DatidiFatturazione": "Billing information",
    "RagioneSociale": "Company Name",
    "PartitaIVA": "VAT number",
    "P.IVA": "VAT",
    "CodiceSDI": "SDI Code",
    "FatturazioneElettronica": "Electronic Invoicing",
    "Provincia": "Province",
    "Paese": "Country",
    "Disconnessione": "Disconnection",
    "StatoCandidatura": "Application status",
    "CompletamentoIscrizione": "In this section you can complete your enrolment process for the chosen course and check the progress of each step.",
    "IdoneitàCandidato": "This icon represents the candidate's suitability",
    "Documenti": "Documents",
    "StatoDocumenti": "This icon represents the status of the requested documents",
    "Edizione": "Edition",
    "ConfermaEdizione": "This icon represents the confirmation of the course edition",
    "Iscrizione": "Signing Up",
    "DocumentazioneIscrizione": "This icon indicates the registration documentation",
    "StatoPagamento": "Payment Status",
    "StatoPagamenti": "This icon represents the payment status",
    "Pagamenti": "Payments",
    "Completata": "Completed",
    "Tesi": "EXAM AREA",
    "BuoniOrd": "Order vouchers for raw materials and equipment for final examination",
    "NoDocumento": "No Exam uploaded",
    "CaricaTesi": "Upload Exam",
    "ModificaTesi": "Edit Exam",
    "TesiInt": "ICP students must upload only the pdf work",
    "Modifica": "Edit",
    "Limite":"upload a file < 5MB",
    "AggiungiPiatti": "Add Dishes",
    "Nomedelpiatto": "Name of the dish",
    "InserisciPiatto": "Enter dish name",
    "Inserisci": "Enter ",
    "InserisciDescrizione": "Enter description ",
    "InserisciTitolo": "Enter title",
    "Quantità": "Quantity",
    "Attrezzatura": "Equipment",
    "Ingredienti": "Ingredients",
    "ProcessoIscrizione": "This icon represents the overall status of the enrollment process",
    "IdentificaRuolo": "What is your title?",
    "StudenteIPSAR": "Culinary Arts Student",
    "Genitore": "Parent",
    "ProfessionistaHORECA": "HORECA professional",
    "DocenteIPSAR": " Culinary Arts Teacher",
    "CandidatoAlma": "ALMA applicant",
    "StudenteAlma": "Alma student",
    "DiplomatoAlma": "Alma graduate",
    "Inserisciemail": "Enter email",
    "Inserisciun": "Enter Username",
    "TestoRegistrazione":"Now enter your email as username and a password to register in your personal area and proceed with the application",
    "Installazione": "Do you want to install the webapp?",
    "Installa": "Install",
    "CaricaDocumento": "Upload the document",
    "ScegliFile": "Choose the file to upload",
    "Filecaricare": "Upload the file:",
    "FilecaricareLabel": "It is suggested PDF file format, not greater than 5MB",
    "FilecaricareDocLabel": "It is suggested PDF or JPEG file format, not greater than 5MB",
    "Dimensioni": "Size:",
    "DimensioniEccessive": "File size exeeded the size limit allowed",
    "Carica": "Upload",
    "NomeFileTesi": "Name of Exam uploaded:",
    "Studi": "Education",
    "InserireCurriculum": "Submit CV",
    "CaricaCurriculum": "Upload CV",
    "Letuecredenziali": "Your credentials",
    "ATTENZIONE": "WARNING!",
    "SCARICAMODELLOCV": "DOWNLOAD CV MODEL",
    "INIZIA": "START",
    "Fine": "End!",
    "Afghanistan":"Afghanistan",
    "Albania":"Albania",
    "Algeria":"Algeria",
    "Samoa Americane":"American Samoa",
    "Andorra":"Andorra",
    "Angola (compresa Cabinda)":"Angola",
    "Antigua E Barbuda":"Antigua and Barbuda",
    "Armenia":"Armenia",
    "Argentina":"Argentina",
    "Australia":"Australia",
    "Austria":"Austria",
    "Azerbaigian":"Azerbaijan",
    "Bahamas":"Bahamas",
    "Bahrein":"Bahrain",
    "Bangladesh":"Bangladesh",
    "Barbados":"Barbados",
    "Belarus":"Belarus",
    "Belgio":"Belgium",
    "Belize":"Belize",
    "Bhutan":"Bhutan",
    "Bolivia":"Bolivia",
    "Bosnia-erzegovina":"Bosnia and Herzegowina",
    "Botswana":"Botswana",
    "Brasile":"Brazil",
    "Brunei":"Brunei Darussalam",
    "Bulgaria":"Bulgaria",
    "Burkina-faso":"Burkina Faso",
    "Burundi":"Burundi",
    "Cambogia":"Cambodia",
    "Camerun":"Cameroon",
    "Canada":"Canada",
    "Capo Verde":"Cape Verde",
    "Repubblica Centrafricana":"Central African Republic",
    "Ciad":"Chad",
    "Cile":"Chile",
    "Cina":"China",
    "Colombia":"Colombia",
    "Comore":"Comoros",
    "Costarica":"Costa Rica",
    "Costa D'avorio":"Cote D'Ivoire",
    "Croazia":"Croatia",
    "Cuba":"Cuba",
    "Cipro":"Cyprus",
    "Repubblica Ceca":"Czech Republic",
    "Danimarca":"Denmark",
    "Dominica":"Dominica",
    "Repubblica Dominicana":"Dominican Republic",
    "Ecuador":"Ecuador",
    "Egitto":"Egypt",
    "El Salvador":"El Salvador",
    "Guinea Equatoriale":"Equatorial Guinea",
    "Eritrea":"Eritrea",
    "Estonia":"Estonia",
    "Etiopia":"Ethiopia",
    "Figi":"Fiji",
    "Finlandia":"Finland",
    "Francia (compreso Monaco E Dipartimenti Oltemare)":"France",
    "Gabon":"Gabon",
    "Gambia":"Gambia",
    "Georgia":"Georgia",
    "Germania":"Germany",
    "Ghana":"Ghana",
    "Grecia":"Greece",
    "Grenada":"Grenada",
    "Guatemala":"Guatemala",
    "Guinea":"Guinea",
    "Guinea Bissau":"Guinea-bissau",
    "Guyana":"Guyana",
    "Haiti":"Haiti",
    "Honduras (compreso Isole Swan)":"Honduras",
    "Hong Kong":"Hong Kong",
    "Ungheria":"Hungary",
    "Islanda":"Iceland",
    "India":"India",
    "Indonesia":"Indonesia",
    "Iran":"Iran (Islamic Republic of)",
    "Iraq":"Iraq",
    "Irlanda":"Ireland",
    "Israele":"Israel",
    "Italia":"Italy",
    "Giamaica":"Jamaica",
    "Giappone":"Japan",
    "Giordania":"Jordan",
    "Kazakistan":"Kazakhstan",
    "Kenya":"Kenya",
    "Kiribati":"Kiribati",
    "Kuwait":"Kuwait",
    "Kirghizistan":"Kyrgyzstan",
    "Laos":"Lao People's Democratic Republic",
    "Lettonia":"Latvia",
    "Libano":"Lebanon",
    "Lesotho":"Lesotho",
    "Liberia":"Liberia",
    "Libia":"Libyan Arab Jamahiriya",
    "Liechtenstein":"Liechtenstein",
    "Lituania":"Lithuania",
    "Lussemburgo":"Luxembourg",
    "Macao":"Macau",
    "Macedonia, the former Yugoslav Republ. Of":"Macedonia, The Former Yugoslav Republic of",
    "Madagascar":"Madagascar",
    "Malawi":"Malawi",
    "Malaysia Peninsulare Ed Orientale":"Malaysia",
    "Maldive":"Maldives",
    "Mali":"Mali",
    "Malta (gozo, Comino)":"Malta",
    "Isole Marshall":"Marshall Islands",
    "Mauritania":"Mauritania",
    "Maurizio Isola":"Mauritius",
    "Messico":"Mexico",
    "Federazione Degli Stati Di Micronesia":"Micronesia, Federated States of",
    "Moldavia":"Moldova, Republic of",
    "Monaco":"Monaco",
    "Mongolia":"Mongolia",
    "Marocco":"Morocco",
    "Mozambico":"Mozambique",
    "Myanmar":"Myanmar",
    "Namibia":"Namibia",
    "Nauru":"Nauru",
    "Nepal":"Nepal",
    "Antille Olandesi":"Netherlands",
    "Nuova Zelanda":"New Zealand",
    "Nicaragua (compreso Isole Corn)":"Nicaragua",
    "Niger":"Niger",
    "Nigeria":"Nigeria",
    "Norvegia (compresa Jan Mayen;escluso Arc.svalbard)":"Norway",
    "Oman":"Oman",
    "Pakistan":"Pakistan",
    "PALAU":"Palau",
    "Panama":"Panama",
    "Papuasia - Nuova Guinea":"Papua New Guinea",
    "Paraguay":"Paraguay",
    "Peru'":"Peru",
    "Filippine":"Philippines",
    "Polonia":"Poland",
    "Portogallo":"Portugal",
    "Qatar":"Qatar",
    "Romania":"Romania",
    "Russia":"Russian Federation",
    "Ruanda":"Rwanda",
    "San Cristoforo E Nevis":"Saint Kitts and Nevis",
    "Saint Lucia":"Saint Lucia",
    "Saint Vincent":"Saint Vincent and the Grenadines",
    "Samoa Occidentali":"Samoa",
    "San Marino":"San Marino",
    "Sao Tome' E Principe":"Sao Tome and Principe",
    "Arabia Saudita":"Saudi Arabia",
    "Senegal":"Senegal",
    "Seychelles E Dipendenze":"Seychelles",
    "Sierra Leone":"Sierra Leone",
    "Singapore":"Singapore",
    "Slovacchia":"Slovakia (Slovak Republic)",
    "Slovenia":"Slovenia",
    "Isole Salomone":"Solomon Islands",
    "Somalia":"Somalia",
    "Sud Africa":"South Africa",
    "Spagna (compr.baleari,canarie,ceuta,melilla)":"Spain",
    "Sri Lanka":"Sri Lanka",
    "Sudan":"Sudan",
    "Suriname":"Suriname",
    "Svezia":"Sweden",
    "Svizzera":"Switzerland",
    "Siria":"Syrian Arab Republic",
    "Taiwan":"Taiwan",
    "Tanzania (tanganica, Zanzibar, Penba)":"Tanzania, United Republic of",
    "Thailandia":"Thailand",
    "Togo":"Togo",
    "Tonga":"Tonga",
    "Trinidad E Tobago":"Trinidad and Tobago",
    "Tunisia":"Tunisia",
    "Turchia":"Turkey",
    "Turkmenistan":"Turkmenistan",
    "Tuvalu":"Tuvalu",
    "Uganda":"Uganda",
    "Ucraina":"Ukraine",
    "Emirati Arabi Uniti":"United Arab Emirates",
    "Regno Unito (Gran Bretagna, Irlanda Nord)":"United Kingdom",
    "Stati Uniti d'America":"United States",
    "Uruguay":"Uruguay",
    "Uzbekistan":"Uzbekistan",
    "Vanuatu":"Vanuatu",
    "Citta' Del Vaticano":"Vatican City State (Holy See)",
    "Venezuela":"Venezuela",
    "Vietnam":"Viet Nam",
    "Yemen":"Yemen",
    "Serbia":"Serbia",
    "Congo (repubblica)":"The Democratic Republic of Congo (ex Zaire)",
    "Zambia":"Zambia",
    "Zimbabwe":"Zimbabwe",
    "Montenegro":"Montenegro",
    "Palestinian Territory, Occupied":"Territori dell'Autonomia Palestinese",
    "Timor-Leste":"Timor Orientale",
    "Repub. Federale Di Jugoslavia (serbia,montenegro)":"Jugoslavia (Serbia-Montenegro)",
    "Apolide":"Apolide",
    "Scaduto":"Expired",
    "Valido":"Valid",
    "Richiesto":"Requested",
    "Non valido":"Not valid",
    "In attesa di validazione":"Awaiting validation",
    "Motivazione":"Reason",
    "Dati Accesso":"Login Info",
    "Dati Anagrafici":"Personal Info",
    "Fatturazione Housing":"Billing Housing",
    "Fatturazione Corsi":"Billing Courses",
    "NomeCognome":"Name Surname",
    "MeStesso":"My Self",
    "PersonaGiuridica":"Company or Enterprise",
    "PersonaFisica":"Other Person",
    "Intestatario":"Accountholder",
    "Abbigliamento":"Clothes",
    "TagliaGiacca":"Jacket Size",
    "TagliaPantalone":"Trouser Size",
    "TagliaPolo":"Polo Size",
    "TagliaScarpe":"Shoe Size",
    "TagliaGilet":"Vest Size",
    "TagliaCamicia":"Shirt Size",
    "Conferma":"Confirm",
    "Documento di riconoscimento":"Identification document",
    "TipoDocumento":"Document Type",
    "Numero":"Document Number",
    "DataRilascio":"Release Date",
    "DataScadenza":"Expiretion Date",
    "EnteRilascio":"Place of issue",
    "DatiCauzione":"Data relating to refund deposit",
    "DatiLegRap":"Legal Agent Data",
    "NomeLegRap":"Legal Agent Name",
    "CognomeLegRap":"Legal Agent Surname",
    "MailLegRap":"Legal Agent Mail",
    "NotifyTitle": "Notification",
    "RegolamentoDidattica": "Student Handbook",
    "ProtocolloCovid": "Covid Protocol",
    "Accettata": "Accepted",
    "Rifiutata": "Refused",
    "In attesa di approvazione": "Pending Approval",
    "In Fase di Selezione": "Selection Phase",
    "Conferma Email":"Confirm E-Mail",
    "Altro":"More",
    "boxesHome1": {
        "alttext": "Alma School information",
        "title": "General Information",
        "menulist": ["Why Alma", "Courses & Open Days", "Recipes and News", "Payment facilities"]
    },
    "boxesCorsi1": {
        "alttext": "Courses",
        "title": "Courses",
        "extlink": "https://www.alma.scuolacucina.it/en/courses/"
    },
    "boxesRicette1": {
        "alttext": 'Recipes',
        "title": 'Recipes',
        "extlink": ""
    },
    "boxesInfo1": {
        "alttext": 'Why choose Alma',
        "title": 'Why choose Alma',
        "extlink": 'https://www.alma.scuolacucina.it/en/why-choose-alma-culinary-arts-school/'
    },
    "boxesHousing1": {
        "alttext": 'My ALMA Housing',
        "title": 'My ALMA Housing'
    },
    "boxesAssistenza1": {
        "alttext": 'Ask for information',
        "title": 'Ask for information',
        "extlink": 'https://customer.alma.scuolacucina.it/iscrizioni/information'
    },
    "boxesStudente1": {
        "alttext": 'School regulations',
        "title": 'School regulations'
    },
    "boxesDidattica1": {
        "alttext": 'Hyperplanning',
        "title": 'Hyperplanning'
    },
    "boxesDiplomati1": {
        "alttext": "Alma Link",
        "title": "Alma Link",
        "extlink": "https://www.alma.scuolacucina.it/en/career-service/"
    },
    "boxesExtraDidattica1": {
        "alttext": 'Apply for events',
        "title": 'Apply for events'
    },
    "boxesCandidati1": {
        "alttext": 'Choose your course',
        "title": 'Choose your course',
        "menulist": ['Choose your course and apply on the website'],
        "extlink": 'https://www.alma.scuolacucina.it/en/applicants/',
    },
    "boxesSponsor1": {
        "alttext": "Recipes",
        "title":   "Recipes",
        "extlink": "https://www.alma.scuolacucina.it/sostenibilita-in-cucina-ricette-partner/"
    },
    "boxesHome2": {
        "alttext": "Applicantion area in ALMA",
        "title": "Candidacy Area",
        "menulist": ["Choose your course", "Application Status", "Ask for Support", "Application Log"]
    },
    "boxesCorsi2": {
        "alttext": "Open Day",
        "title": "Open Day",
        "extlink": "https://www.alma.scuolacucina.it/en/the-school/almas-open-days/"
    },
    "boxesRicette2": {
        "alttext": "Alma wines",
        "title": "Alma wines",
        "extlink": ""
    },
    "boxesInfo2": {
        "alttext": 'Courses and Open Days',
        "title": 'Courses and Open Days',
        "extlink": ''
    },
    "boxesAlma2": {
        "alttext": 'First day',
        "title": 'First day',
        "extlink": 'https://www.alma.scuolacucina.it/en/applicants/'
    },
    "boxesHousing2": {
        "alttext": 'Housing regulations',
        "title": 'Housing regulations'
    },
    "boxesAssistenza2": {
        "alttext": 'Student Office',
        "title": 'Student Office',
        "extlink": 'https://www.alma.scuolacucina.it/en/student-office/'
    },
    "boxesStudente2": {
        "alttext": 'Utility',
        "title": 'Utility',
    },
    "boxesDidattica2": {
        "alttext": 'eLearning',
        "title": 'eLearning',
    },
    "boxesDiplomati2": {
        "alttext": "Refresher courses",
        "title": "Refresher courses",
        "extlink": ''
    },
    "boxesExtraDidattica2": {
        "alttext": 'Local events',
        "title": 'Local events'
    },
    "boxesCandidati2": {
        "alttext": 'Application status',
        "title": 'Application status',
        "menulist": ['Check you application status'],
        "extlink": ""
    },
    "boxesSponsor2": {
        "alttext": "Webinar",
        "title":   "Webinar",
        "extlink": "https://www.alma.scuolacucina.it/webinar-e-masterclass/"
    },
    "boxesHome3": {
        "alttext": "My Alma Life",
        "title": "My Alma Life",
        "menulist": ["School regulations", "First day", "Student Area", "My Housing"]
    },
    "boxesCorsi3": {
        "alttext": "Information request",
        "title": "Information request",
        "extlink": "https://customer.alma.scuolacucina.it/iscrizioni/information?_gl=1*glmys*_ga*MzUyNjI5MTU5LjE2Mzg1MzYzMzk.*_ga_4ETFXD4R5E*MTY4NTk2NzM0My41My4xLjE2ODU5NjgyMTguNDUuMC4w*_gcl_au*MTA2ODkyMi4xNjgyNTkwMDYy&_ga=2.17587065.1970717658.1685967344-352629159.1638536339"
    },
    "boxesRicette3": {
        "alttext": 'Bread',
        "title": 'Bread',
        "extlink": ""
    },
    "boxesInfo3": {
        "alttext": 'Recipes and News',
        "title": 'Recipes and News',
        "extlink": ""
    },
    "boxesAlma3": {
        "alttext": 'Student Area',
        "title": 'Student Area',
        "extlink": ""
    },
    "boxesHousing3": {
        "alttext": 'Maintenance requests',
        "title": 'Maintenance requests'
    },
    "boxesAssistenza3": {
        "alttext": 'Notifications',
        "title": '(Notifications)'
    },
    "boxesStudente3": {
        "alttext": 'Education activities',
        "title": 'Education activities'
    },
    "boxesDidattica3": {
        "alttext": 'Traineeship',
        "title": 'Traineeship'
    },
    "boxesDiplomati3": {
        "alttext": "ALMA events",
        "title": "ALMA events",
        "extlink": 'https://www.alma.scuolacucina.it/en/news/'
    },
    "boxesExtraDidattica3": {
        "alttext": 'Affiliations',
        "title": 'Affiliations'
    },
    "boxesCandidati3": {
        "alttext": 'Ask for support',
        "title": 'Ask for support',
        "menulist": [''],
        "extlink": ''
    },
    "boxesSponsor3": {
        "alttext": "News",
        "title":   "News",
        "extlink": "https://marketing.scuolacucina.it/le-news-dei-partner-di-alma"
    },
    /*"boxesHome4": {
        "alttext": "Support",
        "title": "Support",
        "menulist": ["Ask for information", "Student Office", "Notifications", "Work with us"]
    },*/
    "boxesCorsi4": {
        "alttext": "How to enrol",
        "title": "How to enrol",
        "extlink": "https://www.alma.scuolacucina.it/come-iscriversi/"
    },
    "boxesRicette4": {
        "alttext": 'Recipes',
        "title": 'Recipes',
        "extlink": 'https://www.alma.scuolacucina.it/en/special-recipes-designed-by-alma/'
    },
    "boxesInfo4": {
        "alttext": 'payment facilities',
        "title": 'payment facilities',
        "extlink": ""
    },
    "boxesAlma4": {
        "alttext": 'My ALMA Housing',
        "title": 'My ALMA Housing',
        "extlink": ""
    },
    "boxesHousing4": {
        "alttext": 'Emergency information',
        "title": 'Emergency information'
    },
    "boxesAssistenza4": {
        "alttext": 'Work with us',
        "title": 'Work with us',
        "extlink": 'https://www.alma.scuolacucina.it/en/work-with-us/'
    },
    "boxesStudente4": {
        "alttext": 'Events and spare time',
        "title": 'Events and spare time'
    },
    "boxesStudente5": {
        "alttext": 'Reservations',
        "title": 'Reservations'
    },
    "boxesStudente6": {
        "alttext": 'Living and studying in Parma',
        "title": 'Living and studying in Parma'
    },
    "boxesDidattica4": {
        "alttext": 'EXAM AREA',
        "title": 'EXAM AREA'
    },
    "boxesCandidati4": {
        "alttext": 'Application log',
        "title": 'Application log',
        "menulist": [''],
        "extlink": ""
    },
    "boxesAlma5": {
        "alttext": 'Graduates',
        "title": 'Graduates',
        "extlink": ""
    },
    "boxesRicette5": {
        "alttext": 'News',
        "title": 'News',
        "extlink": 'https://www.alma.scuolacucina.it/en/news/'
    },
    "boxesAlma6": {
        "alttext": "Partners",
        "title":   "Partners",
        "extlink": ""
    },
    "descrizioneAlloggio": "Accommodation description",
    "rifiuti": "Selective waste collection",
    // "rifiuti": "Recycling",
    "CaricaDocumentoIdentita":"Upload Identification Document",
    "HaiUnaLogin":"Do you have a login?",
    "subtitleVoucher":"Redeem your Voucher:",
    "AttualeOccupazione":"Current Occupation",
    "OperatoreCucina":"Kitchen operator",
    "OperatorePasticceria":"Pastry operator",
    "OperatoreSala":"Room operator",
    "FBManager":"F&B Manager",
    "AttivitàRistorative":"Owner of restaurant business",
    "IndirizzodiStudio":"Field of Study",
    "bloccomodifiche":"It's not possible to apply other changes",
    "CaricaDossier" : "Upload Dossier",
    "DossierInt": "Upload attendance register and report card",
    "ModificaDossier":"Modify Dossier",
    "NoDocumentoDossier":"No Intership Dossier uploaded",
    "NomeFileDossier":"Name of Intership Dossier uploaded:",
    "Visualizza":"View",
    "GuidaMaterie":"Guide raw materials",
    "UnitaMisura":"Unit of measure",
    "NomeIng": "Name",
    "NoIng": "No ingredients requested",
    "NoAttr": "No equipment requested",
    "NeedNo": "I need no ",
    "TipoPiatto" : "Type of dish",
    "Categoria":"Category",
    "Antipasto":"Starter",
    "Primo piatto":"First course",
    "Secondo piatto":"Second dish",
    "Dolce":"Dessert",
    "Stuzzichino":"Appetizer",
    "Piatto unico":"Single dish",
    "Torta":"Cake",
    "Dessert monoporzione":"Single-portion dessert",
    "Dessert":"Dessert",
    "Mignon":"Mignon",
    "Dessert al piatto":"Plate dessert",
    "Produzione pane di libera ispirazione":"Bread production of free inspiration",
    "Prodotto da colazione sfogliato o lievitato":"Puffed or leavened breakfast product",
    "Biscotteria":"Biscuits",
    "Focaccia salata":"Salty focaccia",
    "loggato":"WARNING: you are already logged in, your previous data will be replaced",

    /* E-COMMERCE */
    "subtitleAcquisti": "Shop",
    "CercaProdotto": "Search anything",
    "Carrello": "Your cart",
    "Articoli": "Items",
    "Riepilogo": "Summary",
    "ImportoTotale": "Amount",
    "Pagamento": "Buy now",
    "subtitleCommerce": "Ecommerce",
    "boxesCommerce1": {
        "alttext": "Shop",
        "title":   "Shop"
    },
    "boxesCommerce2": {
        "alttext": "History",
        "title":   "History"
    },
    "boxesCommerce3": {
        "alttext": "Badge Top-Up",
        "title":   "Badge Top-Up"
    },
    "boxesHome4": {
        "alttext": "Ecommerce",
        "title": "Ecommerce",
        "menulist": ["Shop", "History", "Badge Top-Up"]
    },
    "Qta": "Qty",
    "Aggiungicarrello": "Add to Cart",
    "Acquistorapido": "Fast Checkout",
    "Importototale": "Total",
    "Acquistaora": "Go to Payment",
    "AggiuntoCarrello": "Added to cart",

    "Prodotto": "Product",
    "Quantita": "Quantity",
    "PUnita": "Unit price",
    "Totale": "Total",
    "TotCart": "Total price (VAT included)",
    "ProcediPagamento": "Pay now",

    "Prezzo": "Price",
    "Taglia": "Size",
    "Idoneo": "Available to",
    "FiltraPer": "Filter...",

    "Categorie": "Categories",
    "Corsi": "Courses",
    "Reset": "Reset filters",
    //"Abbigliamento": "Apparel",
    "Libri": "Books",
    "Utensili": "Tools",

    "NewTicket": "New Ticket",
    "EnterCat": "Enter category",
    "NeedHelp": "Need help?"
}